import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import State from '../data/State';
import Stream from '../data/Stream';
import { sounds } from '../data/assets';
import { screen } from '../types/enums';
import User from '../data/User';

export default observer(() => {
  useEffect(() => {
    const src  = sounds.find(data => data.name === 'mainSound').src;
    State.sound = new Audio(src);
    State.sound.loop = true;
    State.getInteractive() && State.getScreen() === screen.MAIN && State.sound.play();
    document.onclick = () => {
      State.sound.paused && State.getScreen() === screen.MAIN && State.sound.play();
    }

    if (Stream.isPrize()) {
      showMessage('Спасибо, данные отправлены!');
      Stream.setTutorial(false);
    } else if (Stream.isTutorial()) {
      setTutorial(true);
      Stream.setTutorial(false);
    }
  }, []);
  const [message, setMessage] = useState('');
  const [tutorial, setTutorial] = useState(false);

  const showMessage = (text: string) => {
    if (message !== '') return; 
    setMessage(text);
    setTimeout(() => {
      setMessage('');
    }, 3900);
  }

  const users = () => {
    return true;
    if ([
      2314852,
      191781124,
      456849156,
      
    ].find(id => id === User.getUser().id)) {
      return true;
    }
    return false;
  }

  return (
    <div className='page'>
      {/* <div className='friday'></div> */}
      <div className='menu-block-image'></div>
      <div className='menu-footer-block'>
        <div className='menu-block-buttons'>
          { Stream.isReady() && users() ?
            <div className='button-supergame-enable' onClick={() => {
              State.setScreen(screen.STREAM);
              State.playSound('button');
            }}>суперигра в эфире</div>
            :
            <div className='button-supergame-disable' onClick={() => {
              showMessage('Суперигра с Тиной Канделаки скоро начнется! Включай телеканал Пятница! и готовься доказать, что ты умнее всех!');
            }}>суперигра</div>
          }
        </div>
        <div className='footer' style={{ height: 120 }}>
          <div className='footer-main'>
            <div className='btn-main-red'
              onClick={() => {
                State.setScreen(screen.READY);
                State.playSound('button');
              }}
            >ИГРАТЬ</div>
            <div className='btn-main-blue' onClick={() => {
              State.setScreen(screen.RATINGS);
            }}>ЛИДЕРЫ</div>
            <div className='btn-main-blue' onClick={() => {
              State.setScreen(screen.RULES);
            }}>ПРАВИЛА</div>
          </div>
        </div>
      </div>
      { message !== '' && <div className='message-block'>
        <div className='message'>{ message }</div>
      </div> }
      { tutorial && <div className='tutorial-block' onClick={() => setTutorial(false)}>
        <div className='tutorial-message'>Суперигра с Тиной Канделаки<br />завершилась. Возвращайся<br />в следующий четверг!</div>
        <div className='tutorial-footer'>
          <div className='footer-main'>
            <div className='btn-main-red'>ИГРАТЬ</div>
            <div className='tutorial-btn-main-space'></div>
            <div className='tutorial-btn-main-space'></div>
          </div>
          <div className='tutorial-bubble'></div>
        </div>
      </div> }
    </div>
  );
});