import main from '../assets/images/main.png';
import buttonBlue from '../assets/images/button-blue.png';
import buttonRed from '../assets/images/button-red.png';
import buttonGrey from '../assets/images/button-grey.png';
import logo from '../assets/images/logo.png';
import blueHeader from '../assets/images/blue-header.png';
import avatar from '../assets/images/avatar.png';
import star from '../assets/images/star.png';
import tina from '../assets/images/tina.jpg';
import gradient from '../assets/images/gradient.png';
import bgTimer from '../assets/images/bg-timer.png';
import decor1 from '../assets/images/decor-1.png';
import decor2 from '../assets/images/decor-2.png';
import decor3 from '../assets/images/decor-3.png';
import decor4 from '../assets/images/decor-4.png';
import decor5 from '../assets/images/decor-5.png';
import bgGameTimer from '../assets/images/bg-game-timer.png';
import questions from '../assets/images/questions.jpg';
import rulesGradient from '../assets/images/rules-gradient.png';
import tutorial from '../assets/images/tutorial.png';

import tina3 from '../assets/video/03.mp4';
import tina31 from '../assets/video/31.mp4';
import tina41 from '../assets/video/41.mp4';

import button from '../assets/sounds/button.mp3';
import rightAnswer from '../assets/sounds/right-answer.mp3';
import wrongAnswer from '../assets/sounds/wrong-answer.mp3';
import mainSound from '../assets/sounds/main.mp3';
import round3 from '../assets/sounds/round-3.mp3';
import timer from '../assets/sounds/timer.mp3';

const images = [
  main,
  buttonBlue,
  buttonRed,
  buttonGrey,
  logo,
  blueHeader,
  avatar,
  star,
  tina,
  gradient,
  bgTimer,
  decor1,
  decor2,
  decor3,
  decor4,
  decor5,
  bgGameTimer,
  questions,
  rulesGradient,
  tutorial
];
const video = [
  tina3,
  tina31,
  tina41
];
const sounds = [
  { src: button, name: 'button' },
  { src: rightAnswer, name: 'rightAnswer' },
  { src: wrongAnswer, name: 'wrongAnswer' },
  { src: mainSound, name: 'mainSound' },
  { src: round3, name: 'round3' },
  { src: timer, name: 'timer' }
];

export {
  images,
  video,
  sounds,

  main,
  buttonBlue,
  buttonRed,
  buttonGrey,
  logo,
  blueHeader,
  avatar,
  star,
  tina,
  gradient,
  bgTimer,
  decor1,
  decor2,
  decor3,
  decor4,
  decor5,
  bgGameTimer,
  questions,

  tina3,
  tina31,
  tina41
}