import axios from 'axios';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import State from '../data/State';
import Stream from '../data/Stream';
import User from '../data/User';
import { screen } from '../types/enums';

const STREAM_URL = process.env.REACT_APP_DEV ? 'http://localhost:4000' : process.env.REACT_APP_STREAM;

export default observer(() => {
  const [checkbox, setCheckbox] = useState(false);
  const [list, setList] = useState(false);
  const [bank, setBank] = useState('');
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [disable, setDisable] = useState(false);

  const send = () => {
    axios.post(STREAM_URL + '/api/sendPrize', {
      search: User.getSearch(),
      id: User.getUser().id,
      name: name,
      mail: mail,
      phone: phone,
      bank: bank
    }).then(() => {
      Stream.setPrize(true);
      State.setScreen(screen.MAIN);
    }).catch(e => setDisable(false));
  }

  const setFormatPhone = (phone: string) => {
    if (phone.length < 3) {

      if (phone !== '+7' && phone !== '+' && phone !== '') {
        phone = '+7';
      }
    } else {

      if (phone[0] !== '+') {
        phone = '+' + phone;
      }

      if (phone[1] !== '7') {
        phone = phone[0] + '7' + phone.slice(2);
      }
      phone = '+7' + phone.slice(2).replace(/[^0-9]/g, '');

      if (phone.length > 12) {
        phone = phone.slice(0, 12);
      }
    }
    setPhone(phone);
  }

  const button = name !== '' && bank !== '' && mail !== '' && phone !== '' && checkbox;

  return (
    <div className='stream-screen'>
    <div className='decor-1'></div>
      <div className='decor-5'></div>
      <div className='decor-3'></div>
      <div className='decor-4'></div>
      <div className='stream-screen-block'>
        <div style={{ height: '8.7vw' }}></div>
        <div className='stream-form-header'>заполни форму и получи приз</div>
        <div className='stream-form-block'>
          <div className='stream-form-label'>ФИО</div>
          <input className='stream-form-input' placeholder='Иванов Александр Иванович' type='text' onChange={e => setName(e.target.value) } value={ name } />
          <div className='stream-form-label'>Электронная почта</div>
          <input className='stream-form-input' placeholder='ivanov@mail.com' type='text' onChange={e => setMail(e.target.value) } value={ mail } />
          <div className='stream-form-label'>Номер телефона</div>
          <input className='stream-form-input' placeholder='+7 (900) 123-45-67' type='text' onChange={e => setFormatPhone(e.target.value) } value={ phone } />
          <div className='stream-form-label'>Название банка*</div>
          <div className='stream-form-input stream-select-bank' onClick={() => setList(!list)}>
            { bank !== '' ? <div>{ bank }</div> : <div className='stream-form-bank-placeholder'>Альфа Банк</div> }
            <div className='stream-form-arrow'></div>
            { list && <div className='stream-form-list'>
              <div className='stream-list-bank' onClick={() => setBank('Альфа Банк')}>Альфа Банк</div>
              <div className='stream-list-bank-line'></div>
              <div className='stream-list-bank' onClick={() => setBank('ВТБ')}>ВТБ</div>
              <div className='stream-list-bank-line'></div>
              <div className='stream-list-bank' onClick={() => setBank('Газпромбанк')}>Газпромбанк</div>
              <div className='stream-list-bank-line'></div>
              <div className='stream-list-bank' onClick={() => setBank('Озон банк')}>Озон банк</div>
              <div className='stream-list-bank-line'></div>
              <div className='stream-list-bank' onClick={() => setBank('Райффайзен банк')}>Райффайзен банк</div>
              <div className='stream-list-bank-line'></div>
              <div className='stream-list-bank' onClick={() => setBank('Россельхозбанк')}>Россельхозбанк</div>
              <div className='stream-list-bank-line'></div>
              <div className='stream-list-bank' onClick={() => setBank('Сбербанк')}>Сбербанк</div>
              <div className='stream-list-bank-line'></div>
              <div className='stream-list-bank' onClick={() => setBank('Совкомбан')}>Совкомбанк</div>
              <div className='stream-list-bank-line'></div>
              <div className='stream-list-bank' onClick={() => setBank('Т-Банк (Тинькофф)')}>Т-Банк (Тинькофф)</div>
              <div className='stream-list-bank-line'></div>
              <div className='stream-list-bank' onClick={() => setBank('Яндекс банк')}>Яндекс банк</div>
            </div> }
          </div>
          <div className='stream-form-bank-descr'>*Укажи банк, к которому привязан номер телефона</div>
          <div className='stream-form-checkbox-block'>
            <div className='form-stream-checkbox' onClick={() => setCheckbox(!checkbox)}>
              { checkbox && <div className='form-stream-checkbox-enable'></div> }
            </div>
            <div className='form-stream-checkbox-descr'>Даю согласие на <a href='' target='_blank'>обработку персональных данных</a></div>
          </div>
        </div>
        <div className={'stream-form-button ' + (button ? 'enable' : 'disable') } onClick={() => {
          if (disable) return;
          setDisable(true);
          State.playSound('button');
          send();
        }}>отправить</div>
        <div className='stream-form-cancel-descr'>Нажми на кнопку ниже, если хочешь отказаться от получения приза. Отменить выбор будет невозможно</div>
        <div className='stream-form-cancel' onClick={() => {
          Stream.setTutorial(true);
          State.setScreen(screen.MAIN);
        }}>Отказаться от приза</div>
      </div>
    </div>
  );
});