import axios from 'axios';
import { observer } from 'mobx-react-lite';
import State from '../data/State';
import User from '../data/User';
import Stream from '../data/Stream';
import Utils from '../data/Utils';
import { screen, streamScreen } from '../types/enums';

const STREAM_URL = process.env.REACT_APP_DEV ? 'http://localhost:4000' : process.env.REACT_APP_STREAM;

export default observer(() => {
  const sendAnswer = (answer: number) => {
    if (Stream.isDisable()) return;
    Stream.setDisable(true);
    Stream.setAnswer(answer);

    axios.post(STREAM_URL + '/api/sendAnswer', {
      id: User.getUser().id,
      answer: answer,
      search: User.getSearch()
    }).then(res => {
      res.data?.result ? State.playSound('rightAnswer') : State.playSound('wrongAnswer');
      Stream.setCorrect(res.data?.result);
    });
  }

  const preStream = () => {
    return (
      <div style={{ paddingTop: '3vw' }}>
        <div className='stream-logo'></div>
        <div className='stream-round-header'>первый раунд</div>
        <div className='stream-timer'>{ Utils.timer(Stream.getTimer()) }</div>
        <div className='stream-timer-descr'>Суперигра скоро начнётся!<br />Следи за обратным отсчётом<br />до её начала и смотри <br />«Умнее всех» в эфире Пятницы!</div>
        <div className='stream-back-button' onClick={() => {
          State.setScreen(screen.MAIN);
          State.playSound('button');
        }}>назад</div>
      </div>
    );
  }

  const firstRound = () => {
    const answers = Stream.getQuestion().answers.map(data => {
      if (data.text === '') return null;
      const color =
        Stream.getAnswer() === data.id && Stream.isCorrect() === true ? 'btn-green' :
        Stream.getAnswer() === data.id && Stream.isCorrect() === false ? 'btn-red' :
        'btn-blue';
      return (
        <div
          key={ data.id }
          onClick={() => sendAnswer(data.id)}
          className={ 'stream-btn ' + color }
        >{ data.text }</div>
      );
    });
    return (
      <div className='stream-screen'>
        <div className='decor-1'></div>
        <div className='decor-5'></div>
        <div className='decor-3'></div>
        <div className='decor-4'></div>
        <div className='stream-screen-block'>
          <div className='stream-questions-header'>суперигра. первый раунд</div>
          <div className='stream-logo'></div>
          <div className='stream-questions-count'>вопрос { Stream.getQuestion().order ?? 1 } / { Stream.getQuestion().count ?? 1 }</div>
          <div className='round-question-block'>
            <div className='question-header'>{ Stream.getQuestion().text ?? '' }</div>
            <div className='answers-block'>{ answers }</div>
          </div>
          <div className='stream-questions-timer'>{ Utils.timer(Stream.getTimer()) }</div>
        </div>
      </div>
    );
  }

  const firstDone = () => {
    return (
      <>
        <div className='stream-logo-indent'></div>
        <div className='stream-logo'></div>
        <div className='stream-answers-accepted'>ответы приняты</div>
        <div className='stream-results'>Подводим итоги<br />первого раунда...</div>
        <div className='stream-timer'>{ Utils.timer(Stream.getTimer()) }</div>
      </>
    );
  }

  const firstRatings = () => {
    if (Stream.getRatings().find(data => data.id === User.getUser().id)) {
      return (
        <div className='stream-screen'>
          <div className='decor-5'></div>
          <div className='decor-3'></div>
          <div className='decor-4'></div>
          <div className='stream-screen-block'>
            <div style={{ width: '100%', height: '31.57vw' }}></div>
            <div className='stream-ratings-header'>первый раунд окончен</div>
            <div style={{ width: '100%', height: '9.26vw' }}></div>
            <div className='stream-place-block'>
              <div className='stream-place-headers'>
                <div>Твоё место в рейтинге</div>
                <div style={{ paddingRight: '3vw' }}>Количество баллов</div>
              </div>
              <div className='stream-place-info-block'>
                <div className='stream-place-green'>{ Stream.getPlace() }</div>
                <div className='stream-place-green'><div className='stream-big-star'></div>{ Stream.getScore() }</div>
              </div>
            </div>
            <div className='stream-first-round-top'>Поздравляем! Ты в десятке<br />лучших игроков и проходишь<br />в финал Суперигры!<br />Попади в топ-3 следующего<br />раунда и выиграй<br />денежный приз!</div>
          </div>
        </div>
      );
    } else {
      const users = Stream.getRatings().map((data, index) => {
        return (
          <div key={ index }>
            <div className='stream-user-block'>
              <div className='stream-user-avatar'></div>
              <div className='stream-user-name'>{ index + 1 }. { data.name }</div>
              <div className='stream-small-star'></div>
              <div>{ data.score }</div>
            </div>
            { index < Stream.getRatings().length - 1 && <div className='stream-user-line'></div> }
          </div>
        );
      });
      return (
        <div className='stream-screen'>
          <div className='decor-1'></div>
          <div className='decor-5'></div>
          <div className='decor-3'></div>
          <div className='decor-4'></div>
          <div className='stream-screen-block'>
            <div style={{ width: '100%', height: '10.74vw' }}></div>
            <div className='stream-ratings-header'>первый раунд окончен</div>
            <div className='stream-top-10-header'>Топ-10 игроков раунда</div>
            <div className='stream-top-block'>{ users }</div>
            <div style={{ width: '100%', height: '6.94vw' }}></div>
            <div className='stream-place-block'>
              <div className='stream-place-headers'>
                <div>Твоё место в рейтинге</div>
                <div style={{ paddingRight: '3vw' }}>Количество баллов</div>
              </div>
              <div className='stream-place-info-block'>
                <div className='stream-place-blue'>{ Stream.getPlace() }</div>
                <div className='stream-place-blue'><div className='stream-big-star'></div>{ Stream.getScore() }</div>
              </div>
            </div>
            <div className='stream-top-10-descr'>Увы, тебе не удалось попасть в топ-10<br />игроков раунда. Продолжай играть,<br />чтобы улучшить результат!</div>
          </div>
        </div>
      );
    }
  }

  const preFinal = () => {
    return (
      <div style={{ paddingTop: '3vw' }}>
        <div className='stream-logo'></div>
        <div className='stream-round-header'>финальный раунд</div>
        <div className='stream-timer'>{ Utils.timer(Stream.getTimer()) }</div>
        <div className='stream-timer-descr'>Финальный раунд скоро начнётся.<br />Следи за временем, чтобы<br />не пропустить начало!<br />Удачи!</div>
      </div>
    );
  }

  const finalRound = () => {
    const answers = Stream.getQuestion().answers.map(data => {
      if (data.text === '') return null;
      const color =
        Stream.getAnswer() === data.id && Stream.isCorrect() === true ? 'btn-green' :
        Stream.getAnswer() === data.id && Stream.isCorrect() === false ? 'btn-red' :
        'btn-blue';
      return (
        <div
          key={ data.id }
          onClick={() => sendAnswer(data.id)}
          className={ 'stream-btn ' + color }
        >{ data.text }</div>
      );
    });
    return (
      <div className='stream-screen'>
        <div className='decor-1'></div>
        <div className='decor-5'></div>
        <div className='decor-3'></div>
        <div className='decor-4'></div>
        <div className='stream-screen-block'>
          <div className='stream-questions-header'>суперигра. финальный раунд</div>
          <div className='stream-logo'></div>
          <div className='stream-questions-count'>вопрос { Stream.getQuestion().order ?? 1 } / { Stream.getQuestion().count ?? 1 }</div>
          <div className='round-question-block'>
          <div className='question-header'>{ Stream.getQuestion().text ?? '' }</div>
            <div className='answers-block'>{ answers }</div>
          </div>
          <div className='stream-questions-timer'>{ Utils.timer(Stream.getTimer()) }</div>
        </div>
      </div>
    );
  }

  const finalDone = () => {
    return (
      <>
        <div className='stream-logo-indent'></div>
        <div className='stream-logo'></div>
        <div className='stream-answers-accepted'>ответы приняты</div>
        <div className='stream-results'>Подводим итоги<br />финального раунда...</div>
        <div className='stream-timer'>{ Utils.timer(Stream.getTimer()) }</div>
      </>
    );
  }

  const finalRatings = () => {
    if (Stream.getRatings().find(data => data.id === User.getUser().id)) {
      const place = Stream.getPlace();
      const prize = place === 1 ? '30 000' : place === 2 ? '15 000' : '5 000';
      return (
        <div className='stream-screen'>
          <div className='decor-5'></div>
          <div className='decor-3'></div>
          <div className='decor-4'></div>
          <div className='stream-screen-block'>
            <div style={{ width: '100%', height: '12.77vw' }}></div>
            <div className='stream-ratings-header'>финальный раунд окончен</div>
            <div style={{ width: '100%', height: '8.611vw' }}></div>
            <div className='stream-place-block'>
              <div className='stream-place-headers'>
                <div>Твоё место в рейтинге</div>
                <div style={{ paddingRight: '3vw' }}>Количество баллов</div>
              </div>
              <div className='stream-place-info-block'>
                <div className='stream-place-green'>{ Stream.getPlace() }</div>
                <div className='stream-place-green'><div className='stream-big-star'></div>{ Stream.getScore() }</div>
              </div>
            </div>
            <div className='stream-final-round-top'>Поздравляем! Ты в топ-3<br />самых умных зрителей!<br />Твой выигрыш:<br /><br /><span>{ prize }</span>рублей<br /><br />Заполни форму, чтобы<br />получить приз.</div>
            <div className='stream-next-button' onClick={() => {
              State.setScreen(screen.PRIZE);
              State.playSound('button');
            }}>далее</div>
          </div>
        </div>
      );
    } else {
      const users = Stream.getRatings().map((data, index) => {
        return (
          <div key={ index }>
            <div className='stream-user-block'>
              <div className='stream-user-avatar'></div>
              <div className='stream-user-name'>{ index + 1 }. { data.name }</div>
              <div className='stream-small-star'></div>
              <div>{ data.score }</div>
            </div>
            { index < Stream.getRatings().length - 1 && <div className='stream-leaders-line'></div> }
          </div>
        );
      });
      return (
        <div className='stream-screen'>
          <div className='decor-5'></div>
          <div className='decor-3'></div>
          <div className='decor-4'></div>
          <div className='stream-screen-block'>
            <div style={{ width: '100%', height: '10vw' }}></div>
            <div className='stream-ratings-header'>финальный раунд окончен</div>
            <div style={{ width: '100%', height: '7.5vw' }}></div>
            <div className='stream-place-block'>
              <div className='stream-place-headers'>
                <div>Твоё место в рейтинге</div>
                <div style={{ paddingRight: '3vw' }}>Количество баллов</div>
              </div>
              <div className='stream-place-info-block'>
                <div className='stream-place-blue'>{ Stream.getPlace() }</div>
                <div className='stream-place-blue'><div className='stream-big-star'></div>{ Stream.getScore() }</div>
              </div>
            </div>
            <div className='stream-leaders'>Победители</div>
            <div className='stream-top-block'>{ users }</div>
            <div className='stream-top-3-descr'>Тебе не хватило баллов,<br />чтобы выиграть сегодня.<br />Не расстраивайся<br />и возвращайся играть<br />в следующий четверг!</div>
            <div className='stream-next-button' onClick={() => {
              Stream.setTutorial(true);
              State.setScreen(screen.MAIN);
              State.playSound('button');
            }}>далее</div>
          </div>
        </div>
      );
    }
  }
  
  return Stream.getScreen() === streamScreen.PRE_STREAM ? preStream() :
    Stream.getScreen() === streamScreen.FIRST_ROUND ? firstRound() :
    Stream.getScreen() === streamScreen.FIRST_DONE ? firstDone() :
    Stream.getScreen() === streamScreen.FIRST_RATINGS ? firstRatings() :
    Stream.getScreen() === streamScreen.PRE_FINAL ? preFinal() :
    Stream.getScreen() === streamScreen.FINAL_ROUND ? finalRound() :
    Stream.getScreen() === streamScreen.FINAL_DONE ? finalDone() :
    Stream.getScreen() === streamScreen.FINAL_RATINGS ? finalRatings() :
    null;
});